<template>
    <v-row class="h-100">
        <background page="subscriptions" v-if="!loading && order"></background>

        <v-col v-if="!loading" class="h-100">
            <v-row v-if="order">
                <v-col cols="12">
                    <v-card flat class="rounded-xl shadow" v-if="!loading && order">
                        <v-card-title class="petu-green headline">
                            <span>{{ order.orderNumber.toUpperCase() }}</span>
                        </v-card-title>
                        <v-card-subtitle class="d-flex">
                            <span>{{ formatDate( order.orderDate, 'MMM DD, YYYY @ hh:mm a' ) }}</span>
                            <v-spacer></v-spacer>
                            <v-chip dark small :color="getOrderStatusColor(order.status)">{{ order.status }}</v-chip>
                        </v-card-subtitle>
                        <v-card-text>
                            <v-list class="mt-3">
                                <template v-for="(p, index) in order.products">
                                    <v-list-item class="px-0" :key="p.productId" :to="`/producto/${getProductSlug(p.productId)}`">
                                        <v-badge :content="p.qty" color="primary" bordered overlap offset-x="25" offset-y="20" left>
                                            <v-list-item-avatar>
                                                <v-avatar><v-img :src="getProductImage(p.productId)"></v-img></v-avatar>
                                            </v-list-item-avatar>
                                        </v-badge>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>{{ getProductName(p.productId) }}</v-list-item-title>
                                            <v-list-item-subtitle v-text="getProductPrice(p.productId)"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        
                                        <v-list-item-action v-text="getProductTotal(p.productId, p.qty)"></v-list-item-action>
                                    </v-list-item>
                                    <v-divider v-if="index < order.products.length - 1" :key="index" inset class="py-1"></v-divider>
                                </template>
                            </v-list>
                            <v-card flat rounded="xl" color="grey lighten-5 my-2">
                                <v-card-text>
                                    <v-container fluid>
                                        <v-row>
                                                <v-col cols="6" class="px-0 pb-0 grey--text">Subtotal</v-col>
                                                <v-col cols="6" class="text-right px-0 pb-0 grey--text">{{ toMoney( order.subtotal ) }}</v-col>

                                                <v-col cols="6" class="px-0 pb-0 grey--text" v-if="couponVisible">Descuentos</v-col>
                                                <v-col cols="6" class="text-right px-0 pb-0 grey--text" v-if="couponVisible"> - {{ toMoney( order.discounts ) }}</v-col>
                                                <v-col cols="6" class="px-0 grey--text">Tax</v-col>
                                                <v-col cols="6" class="text-right px-0 grey--text">{{ toMoney( order.tax ) }}</v-col>

                                                <v-col cols="6" class="px-0 title grey--text text--darken-2">Total</v-col>
                                                <v-col cols="6" class="text-right  px-0 title grey--text text--darken-2">{{ toMoney( order.total ) }}</v-col>
                                            </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>

                            <v-card flat rounded="xl" color="grey lighten-5 my-3">
                                <v-card-text>
                                    <div class="subtitle-1">Información de pago</div>
                                    <div v-if="order.paymentMethod == 'stripe'">
                                        <v-list three-line color="transparent">
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar tile>
                                                    <img :src="require(`@/assets/cards/${order.cardType.replace(/ /g, '')}.svg`)" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>Tarjeta de crédito</v-list-item-title>
                                                    <!-- <v-list-item-subtitle>{{ stripeConfirmation.id }}</v-list-item-subtitle> -->
                                                    <v-list-item-subtitle>Last 4: {{ order.cardLast4 }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle>Exp. {{ order.cardExp }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>

                                    <div v-else-if="order.paymentMethod == 'athmovil'">
                                        <v-list color="transparent">
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar tile><img src="@/assets/cards/athmovil.svg"></v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>ATH Móvil</v-list-item-title>
                                                    <v-list-item-subtitle>{{ order.paymentConfirmation }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-card-text>
                            </v-card>

                            <v-card flat rounded="xl" color="grey lighten-5 my-3">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="6">
                                            <div class="title">Billing</div>
                                            <div class="subtitle-1">{{ order.customer.firstName }} {{ order.customer.lastName }}</div>
                                            <div>
                                                {{ order.billingAddress1 }} <br />
                                                {{ order.billingAddress2 }} <br />
                                                {{ order.billingCity }} {{ order.billingState }}, {{ order.billingZipcode }}
                                            </div>
                                        </v-col>
                                        <v-col cols="6" v-show="showShippingAddress">
                                            <div class="title">Shipping</div>
                                            <div class="subtitle-1">{{ order.customer.firstName }} {{ order.customer.lastName }}</div>
                                            <div>
                                                {{ order.shippingAddress1 }} <br />
                                                {{ order.shippingAddress2 }} <br />
                                                {{ order.shippingCity }} {{ order.shippingState }}, {{ order.shippingZipcode }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else class="h-100" align="center" color="blue" style="margin-top: -75px">
                <v-col class="text-caption">
                    <v-card class="rounded-xl">
                        <v-card-text class="text-center">
                            <v-icon size="75" class="petu-orange mb-3">mdi-alert-orcle</v-icon>
                            <div>
                                <span>{{ alertText }}</span>
                                <span v-if="!isLoggedIn" class="ml-1"><router-link to="/login" class="link">aquí</router-link></span>
                            </div>
                        </v-card-text>
                    </v-card>
                 </v-col>
            </v-row>
        </v-col>

        <!-- TODO: make component -->
        <v-dialog v-if="isLoggedIn && order" v-model="loading" hide-overlay persistent width="300">
            <v-card class="petu-green-bg" dark>
                <v-card-text> 
                    <span>Cargando orden</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { PetuCustomer } from '@/classes/customer'
import { PetuProduct } from '@/classes/product'

import { _st } from '@/softech'

import moment from 'moment'
import Background from '@/components/Background'

export default {
	name: 'CheckIns',
    components: { Background },
    props: { orderId: { type: String, default: '' }},
    data:() => ({
        order: {},
        products: [],
        productImages: [],
        loading: true,
    }),
    async mounted() {
        if (this.isLoggedIn) {
            let customer = new PetuCustomer(this.customer)
            this.order = await customer.getOrder(this.orderId)
            for(let p of this.order.products) {
                let product = new PetuProduct()
                await product.load(p.productId)
                let src = await product.getProductImage()
                this.products.push(product.data)
                this.productImages.push({ id: p.productId, src })
            }
        }

        this.loading = false
    },
    methods: {
        toMoney: (amount) => _st.toMoney(amount),
        formatDate( d, format ) {
            return moment(d).format(format);
        },
        getOrderStatusColor(status) {
            switch(status) {
                case 'processing'       : return 'orange';
                case 'completed'        : return 'green';
                case 'cancelled'        : return 'blue-grey';
                case 'pending payment'   : return 'amber';
                case 'shipped'          : return 'teal';
                case 'failed'           : return 'red';
                case 'draft'            : return 'grey';
                case 'refunded'         : return 'purple';
            }
        },
        getProductImage(productId) {
            let p = this.productImages.find(p => {
                return p.id == productId
            });

            return p?.src;
        },
        getProductSlug(productId) {
            const product = this.products.find((p) => p.id == productId)
            return (!_st.isNU(product)) ? product.slug : ''
        },
        getProductName(productId) {
            const product = this.products.find((p) => p.id == productId)
            return (!_st.isNU(product)) ? product.name : ''
        },
        getProductPrice(productId) {            
            const product = this.products.find((p) => p.id == productId)
            if(_st.isNU(product)) return _st.toMoney(0)

            let p = new PetuProduct(product)
            return _st.toMoney(p.getPrice())
        },
        getProductTotal(productId, qty) {
            let prod = this.products.find(p => {
                return p.id == productId;
            });

            if(_st.isNU(prod)) return _st.toMoney(0);

            let p = new PetuProduct(prod);
            return _st.toMoney(p.getPrice() * qty);
        },
    },
    computed: {
        ...mapGetters({ customer: 'auth/customer', isLoggedIn: 'auth/isLoggedIn' }),
        couponVisible() {
            return this.order.coupons.length > 0;
        },
        stripeConfirmation() {
            if( _st.isNU( this.order ) || this.order.paymentMethod != 'stripe' ) return {};
            return _st.isJson( this.order.paymentConfirmation ) ? JSON.parse( this.order.paymentConfirmation ) : {
                payment_method_details: { card: { brand: '', last4: '', exp_month: '', exp_year: '' } },
                id: ''
            };
        },
        cardImg() {
            return `@/assets/cards/${this.stripeConfirmation.payment_method_details.card.brand}.svg`;
        },
        showShippingAddress() {
            return !_st.isNUE( this.order.customer.shippingAddress1 );
        },
        alertText() { 
            return this.isLoggedIn
            ? 'Actualmente no tiene suscripciones'
            : 'Para poder ver sus suscripciones, ingrese a su cuenta'
        },
    }
}
</script>

<style scoped>
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
</style>